import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
} from "@mui/material";
import { useState } from "react";

const Homepage = () => {
  const [text, setText] = useState("");
  const [base64, setBase64] = useState("");
  const [error, setError] = useState();
  const encodeTextToBase64 = (text) => {
    try {
      const _b = btoa(text);
      setBase64(_b);
      setError();
    } catch (error) {
      console.log(error);
      setError("Something went wrong! Please try again.");
    }
  };

  const decodeBase64 = (b) => {
    try {
      const _t = atob(b);
      setText(_t);
      setError();
    } catch (error) {
      console.log(error);
      setError("Something went wrong! Please try again.");
    }
  };

  return (
    <Container>
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          fontSize: "14px",
        }}
      ></Box>
      <Card>
        <CardContent sx={{ padding: "20px" }}>
          <Box>Base 64 Encode / Decode Tool</Box>
          <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
            {error && <Alert severity="error">{error}</Alert>}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: "10px",
            }}
          >
            <TextField
              multiline
              label="Text"
              maxRows={20}
              fullWidth
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            ></TextField>

            <Button
              variant="contained"
              sx={{ margin: "5px" }}
              onClick={() => {
                encodeTextToBase64(text);
              }}
            >
              encode
            </Button>
          </Box>
          <Box
            sx={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
              fontSize: "10px",
            }}
          >
            <TextField
              multiline
              maxRows={20}
              label="Base 64"
              fullWidth
              value={base64}
              onChange={(e) => {
                setBase64(e.target.value);
              }}
            ></TextField>

            <Button
              variant="contained"
              sx={{ margin: "5px" }}
              onClick={() => {
                decodeBase64(base64);
              }}
            >
              decode
            </Button>
          </Box>
        </CardContent>
      </Card>

      <Card></Card>
    </Container>
  );
};

export default Homepage;
